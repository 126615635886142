.notifications-popup {
  @apply grid max-w-lg;
  max-height: 650px;
  min-width: 512px;

  .notifications-popup-header {
    @apply flex justify-between px-6 pt-6;
  }

  .notifications-popup-container {
    @apply grid justify-self-center mb-14 mt-7;
    grid-template-columns: 0.3fr 2fr;
  }
}
