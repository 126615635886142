.enter-keywords-modal-container {
  width: 90vw;
  max-width: 1100px;

  .enter-keywords-modal-body {
    .keywords-header {
      @apply bg-gray-50 rounded-md h-12 flex justify-between items-center p-5 mt-5;
    }
    .keywords-body-container {
      @apply grid grid-cols-2 gap-3 border border-gray-200 rounded-b-md border-t-0 relative bottom-1 p-3;

      .chip-container {
        @apply px-px overflow-x-auto;
        max-height: calc(100vh - 565px);
      }
    }
  }

  .enter-keywords-modal-buttons {
    @apply grid grid-cols-2 mt-8;
  }
}
