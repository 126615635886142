.enter-keywords {
  .keyword-accordion-column {
    @apply mt-0 grid grid-cols-2 gap-2 relative;

    .keywords-column-title {
      @apply hidden;
    }
    .keywords-input-grid {
      @apply pl-px;
    }
    .chip-container {
      @apply relative overflow-y-scroll;
      max-height: calc(100vh - 440px);

      .chip-flex {
        width: calc(100% - 80px);
      }
      .clear-all-btn {
        @apply fixed right-0 -translate-x-2/3;
      }
    }
  }
}
