.filter-modal-content {
    @apply grid gap-5 h-[387px];
    grid-template-columns: 190px 525px;

    .filter-categories {
        @apply border border-l-0 border-gray-200 rounded-r-xl pt-2.5 pr-2.5;

        .menu-item {
            @apply text-body font-medium text-gray-500 pt-2.5 pb-[9px] px-2.5 mb-[3px] cursor-pointer;

            &.selected {
                @apply text-blue-900 bg-blue-50 rounded-lg;
            }
        }
    }

    .category-content {
        @apply border border-gray-200 rounded-xl overflow-y-auto overflow-x-hidden py-[17px] px-1.5;
    }
}