@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-50 h-screen text-body;

  .tippy-wrapper-content {
    @apply py-1 px-2 rounded-md shadow-lg border-solid z-20 bg-white left-9 break-words;
  }

  .info-point {
    @apply p-3 rounded-md shadow-lg border-solid z-20 bg-white left-9;
    content: " ";
    margin-top: -0.5rem;
    border-width: 0.5rem;
    border-style: solid;
    border-color: transparent white transparent transparent;
  }

  .view-info-point {
    @apply p-3 rounded-md shadow-lg right-full border-solid z-20 bg-white;
    content: " ";
    margin-top: -0.5rem;
    border-width: 0.5rem;
    border-style: solid;
    border-color: transparent white transparent transparent;
  }
}

:root {
  --primary: #7c3aed;
  --primary-50: #f5f3ff;
  --primary-100: #ede9fe;
  --primary-200: #ddd6fe;
  --primary-300: #c4b5fd;
  --primary-400: #a78bfa;
  --primary-500: #8b5cf6;
  --primary-600: #7c3aed;
  --primary-700: #6d28d9;
  --primary-800: #5b21b6;
  --primary-900: #4c1d95;

  --secondary: #0ea5e9;
  --secondary-50: #f0f9ff;
  --secondary-100: #e0f2fe;
  --secondary-200: #bae6fd;
  --secondary-300: #7dd3fc;
  --secondary-400: #38bdf8;
  --secondary-500: #0ea5e9;
  --secondary-600: #0284c7;
  --secondary-700: #0369a1;
  --secondary-800: #075985;
  --secondary-900: #0c4a6e;

  --tertiary: #c026d3;
  --tertiary-50: #fdf4ff;
  --tertiary-100: #fae8ff;
  --tertiary-200: #f5d0fe;
  --tertiary-300: #f0abfc;
  --tertiary-400: #e879f9;
  --tertiary-500: #d946ef;
  --tertiary-600: #c026d3;
  --tertiary-700: #a21caf;
  --tertiary-800: #86198f;
  --tertiary-900: #701a75;

  /* total width */
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 12px;
    height: 12px;
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    border-radius: 16px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
    border-radius: 16px;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 3px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}

@layer components {
  .rubric-description {
    @apply text-gray-500 min-w-full col-span-full ml-9 font-medium;
  }
}
