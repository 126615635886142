.textarea {
  .base-input {
    min-height: 100px;
    @apply resize-none disabled:bg-gray-50 disabled:text-gray-300 disabled:border-gray-300 disabled:pointer-events-none;
  }

  .error-message {
    margin-top: 0;
  }

  .textarea-prefix {
    @apply left-1 top-7 bottom-8 flex items-center bg-white;
  }
}
