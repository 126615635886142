.audits-modal {
  @apply grid grid-cols-1;
  width: min(80vw, 662px);

  .items-header {
    @apply grid pb-5 border-b border-gray-200;
  }

  .items-list {
    @apply grid grid-cols-1 min-w-full mt-6 max-h-[40vh] overflow-auto;

    .no-items {
      @apply text-gray-500 text-footnote max-w-2xl justify-self-center mb-3;
    }
  }
}
