.pagination-dropdown {
  .select__control {
    @apply h-8 min-h-8;
  }

  .select__indicators {
    @apply h-8 min-h-8 w-5 min-w-5;
  }

  .select__value-container {
    @apply h-8 min-h-8 w-7 min-w-7;
  }
}
