.keyword-accordion-column {
  @apply mt-3;

  .keywords-column-title {
    @apply text-caption-1 font-medium text-gray-900;
  }

  .keywords-input-grid {
    @apply grid;
    grid-template-columns: auto 4rem;
  }

  .add-new-key-word-input {
    @apply border border-gray-300 rounded-md w-full col-start-1 col-span-2 row-start-1 row-end-1 pl-3 pr-10 resize-none text-caption-1;
  }

  .add-btn-container {
    @apply col-start-2 col-end-2 row-start-1 row-end-1 flex justify-end items-center;

    .add-tag-btn {
      @apply relative text-tertiary-900 text-sub-headline font-medium col-start-2 row-start-1 rounded-md m-1 mr-3;
    }

    .add-tag-btn:hover {
      @apply bg-gray-50;
    }
  }

  .chip-container {
    @apply flex justify-between items-baseline;

    .chip-flex {
      @apply flex flex-wrap gap-2;
    }

    .clear-all-btn:not(.disabled) {
      @apply text-red-500;
    }
  }

  textarea:disabled {
    @apply bg-gray-50;
  }
  textarea::placeholder {
    @apply text-gray-300;
  }
}
