.custom-tag-container {
  @apply grid h-5;
  padding: 0 0 13px;

  .custom-tag-checkbox {
    width: 100%;
  }

  &.input-visible {
    grid-template-columns: 100%;
    padding-bottom: 24px;
  }

  .tag-row {
    display: flex;
  }

  .tag-name-container {
    @apply flex h-5;
  }

  .tag-name-input {
    @apply h-5 text-sub-headline font-medium text-gray-500 outline-none;
  }

  .tag-name-input:focus {
    @apply outline-none border-secondary- border-2 ring-0;
  }

  .custom-tag-btn {
    @apply p-0 h-6 w-6;
  }
}
