.breadcrumbs {
  @apply flex flex-wrap items-center justify-start mb-3 ml-0.5;

  .breadcrumb {
    @apply grid grid-flow-col items-center text-caption-1 text-blue-600 hover:text-blue-800 font-semibold;

    &.active {
      @apply font-normal text-gray-400 pointer-events-none;
    }

    .breadcrumb-separator {
      @apply mx-1 text-gray-400;
    }
  }
}
