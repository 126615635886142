.tags-modal-container {
  @apply grid;
  width: 45rem;

  .tags-modal-header {
    @apply text-title-3 font-semibold text-black;
  }

  .tags-modal-body {
    @apply grid gap-5 border-b  border-gray-200 mb-3;
    grid-template-columns: 2fr 4fr;

    .tags-list-header {
      @apply h-10 mb-5  bg-gray-100 rounded-md text-black text-caption-1 font-medium flex justify-start items-center pl-3;
    }

    .tag-list {
      @apply overflow-y-auto mb-5;
      max-height: 35rem;

      .new-tag-input-container {
        @apply grid;
        grid-template-columns: 1fr 50px;

        .add-new-tag-input {
          @apply border border-gray-300 rounded-md h-8 w-full col-start-1 col-span-2 row-start-1;
        }

        .add-new-tag-input:focus {
          @apply border-blue-500 border-2 outline-none;
        }

        .new-tag-btn-div {
          @apply relative  col-start-2 row-start-1  m-1;
        }

        .save-tag-btn:hover {
          @apply bg-gray-50;
        }

        .new-tag-input {
          input {
            @apply pr-12;
          }
        }
      }
    }
  }

  .tags-legend {
    @apply grid mb-12;
    grid-template-columns: auto 1fr 1fr 2.5fr;

    .legend-title {
      @apply text-black font-medium;
    }

    .legend-tooltip-title {
      @apply text-caption-1 font-medium text-black;
    }

    .legend-tooltip-text {
      @apply text-caption-1 font-normal text-gray-500;
    }
  }

  .button-bar {
    @apply grid grid-cols-2;
  }

  .success-dialog {
    @apply grid;
    grid-template-rows: repeat(3, auto);

    .title {
      @apply text-title-3 font-normal text-black w-full flex justify-center items-center mb-4;
    }

    .text-container {
      @apply flex justify-center items-center mb-7;

      .text {
        @apply text-sub-headline font-medium text-gray-500;
      }
    }

    .button-container {
      @apply flex items-center justify-center;
    }
  }
}
