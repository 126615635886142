.retailer-selector {
  @apply grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 12px;

  .option {
    @apply p-1 border-primary- border rounded-lg 
      text-center text-primary- select-none overflow-hidden overflow-ellipsis
      hover:bg-primary-200 cursor-pointer transition-all;

    &.selected {
      @apply bg-primary- text-white transition-all;
    }
  }
}
