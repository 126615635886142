.new-audit-card {
  @apply flex flex-col border border-gray-300 rounded-[8px] text-center cursor-pointer;
  padding: 12px 16px 12px 16px;

  &.selected {
    @apply bg-violet-50 border-fuchsia-900;

    .new-audit-card-title {
      @apply text-fuchsia-900;
    }
  }

  &.disabled {
    @apply bg-gray-50 cursor-not-allowed;

    .new-audit-card-title,
    .new-audit-card-body {
      @apply text-gray-300;
    }
  }

  .new-audit-card-title {
    @apply mb-[12px] text-body font-semibold;
  }

  .new-audit-card-body {
    @apply mt-[20px] text-caption-1 text-gray-500 h-full;
  }

  .new-audit-card-body > div {
    @apply overflow-hidden text-ellipsis;
  }

  .new-audit-card-description {
  }
}
