.onboarding {
  padding-top: 120px;

  .onboarding-stepper-container {
    @apply 3xl:max-w-[600px] max-w-[400px] mx-auto mb-3;
  }

  .onboarding-step-title {
    @apply text-title-3 font-bold text-gray-900;
  }

  .onboarding-step-description {
    @apply text-body font-medium text-gray-500 mt-1;
  }

  .onboarding-step-body {
    @apply grid sm:grid-cols-1 mt-8 md:grid-cols-2 lg:grid-cols-2 gap-y-3 gap-x-5;
  }

  .component-label[for="userName"] {
    @apply text-gray-300;
  }
}
