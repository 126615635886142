.button {
  @apply inline-flex
      justify-center
      items-center
      text-body
      gap-2.5
      rounded-lg
      py-3
      px-4
      h-10
      select-none
      transition-all
      duration-200;

  &.primary {
    @apply transition-all bg-fuchsia-900 text-white hover:bg-fuchsia-800 active:bg-fuchsia-700;
    &.disabled {
      @apply text-white;
    }
  }

  &.secondary {
    @apply bg-secondary- text-white hover:bg-secondary-400 active:bg-secondary-800 focus:bg-secondary-600;
    &.disabled {
      @apply text-white;
    }
  }

  &.tertiary {
    @apply bg-tertiary- text-white hover:bg-tertiary-400 active:bg-tertiary-800 focus:bg-tertiary-600;
    &.disabled {
      @apply text-white;
    }
  }

  &.outlined {
    @apply bg-white border border-gray-300 text-gray-900 hover:bg-gray-50 active:bg-gray-100 focus:bg-white;
    &.disabled {
      @apply text-gray-300 border-gray-200 bg-white;
    }
    &.secondary {
      @apply px-4 py-4 h-7;
    }
  }

  &.loading {
    @apply pointer-events-none cursor-not-allowed;
  }

  &.submit {
    @apply min-w-24;
  }

  &.disabled {
    @apply bg-gray-300 text-white pointer-events-none cursor-not-allowed;
  }

  &.link {
    @apply bg-transparent px-2 font-medium text-blue-600 hover:bg-transparent active:bg-gray-100 focus:bg-transparent;

    &.secondary {
      @apply text-blue-600 hover:text-blue-800;
    }

    &.tertiary {
      @apply text-tertiary-;
    }

    &.gray {
      @apply text-gray-500;
    }

    &.disabled {
      @apply text-gray-300 hover:text-gray-300;
    }

    &.active {
      @apply text-gray-800;
    }
  }

  &.icon-btn {
    @apply bg-transparent text-gray-500 hover:bg-gray-50 active:bg-gray-100 focus:bg-transparent px-2 rounded-full;
    &.disabled {
      @apply text-gray-300;
    }
  }

  &.danger {
    @apply bg-red-600 text-white hover:bg-red-400 active:bg-red-800 focus:bg-red-600;
    &.disabled {
      @apply bg-gray-300 text-white;
    }
    &.secondary {
      @apply px-4 py-4 h-7;
    }
  }

  &.success {
    @apply bg-green-500 text-white hover:bg-green-400 active:bg-green-800 focus:bg-green-600;
    &.disabled {
      @apply bg-gray-300 text-white;
    }
    &.secondary {
      @apply px-3 py-4 h-7;
    }
  }

  .btn-content {
    @apply grid grid-flow-col gap-x-2;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
