.schedule-audit {
  @apply grid relative;

  .select-grid-card {
    @apply grid;
    grid-template-columns: repeat(auto-fill, minmax(242px, 12px));
    gap: 12px;
  }

  .options {
    @apply grid grid-flow-col justify-start gap-x-10 items-center;

    .recurring-dropdown {
      @apply self-end mt-4;
    }
  }

  .skus-info-point {
    @apply grid grid-flow-col items-center gap-3 border rounded p-3 mt-7 justify-self-end;
  }

  .add-tags-and-keywords-container {
    @apply flex rounded-md flex-wrap justify-start items-center bg-gray-50;
    z-index: 1;

    .block-label {
      @apply bg-gray-50 font-semibold rounded-md;
      padding: 21px;
    }
  }

  .add-tags-and-keywords-details-container {
    @apply flex justify-between items-baseline px-5 pb-1.5 pt-3.5 -mt-2 w-full bg-white border rounded-md;

    .tags-details {
      @apply flex flex-wrap gap-2;
    }

    .clear-all-btn:not(.disabled) {
      @apply text-red-500;
    }
  }
}
