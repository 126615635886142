.navigation {
  @apply grid z-10 w-full fixed;

  .top-container {
    @apply grid justify-between items-center py-2.5 px-6 bg-white;
    grid-template-columns: auto auto;

    .right-button-group {
      @apply grid items-center grid-flow-col justify-end gap-2 text-body;

      .switch-workspace {
        @apply grid grid-flow-col gap-4 place-items-center relative mr-5;

        .switch-workspace-label {
          @apply text-gray-500 text-caption-2 px-1 bg-white absolute -top-2 left-2.5;
          z-index: 1;
        }

        .switch-workspace-input {
          min-width: 320px;
          width: fit-content;
          max-width: 600px;
          position: relative;

          .selected-option-container {
            @apply flex items-center justify-between gap-7 mr-2.5;
          }

          .selected-option {
            max-width: 500px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .select__control--is-focused .selected-option {
            @apply text-gray-300;
          }

          .switch-workspace-role {
            @apply text-caption-1 font-normal text-gray-500 mr-7;
          }

          .switch-workspace-role-divider {
            @apply h-6 border border-gray-200 ml-3;
          }

          .tooltip-icon {
            @apply absolute top-2.5 right-16 text-gray-500;
          }
        }
      }
    }
  }

  .main-menu {
    @apply grid text-white text-center items-center grid-flow-col justify-center py-1.5 gap-2.5 lg:gap-5;
    background: linear-gradient(180deg, #bf2cd6 0%, #66009e 100%);

    .menu-item {
      @apply grid grid-flow-col justify-center gap-2 items-center py-3 pl-2.5 pr-2.5 
        font-medium text-body
        rounded-lg text-white text-left truncate;

      &.active {
        background: rgba(0, 0, 0, 0.3);
      }

      &:hover:not(.active) {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }

  .workspace-dropdown-item {
    @apply grid grid-cols-2 p-4;
    grid-template-columns: 1fr auto;
  }

  .no-plan-warning {
    @apply grid grid-flow-col items-center gap-3 w-full border p-3;
    grid-template-columns: 0fr 2fr;
  }
}
