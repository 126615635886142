.checkbox {
  @apply inline-grid grid-cols-2 items-center w-full;
  grid-template-columns: auto 1fr;

  .checkbox-input {
    @apply cursor-pointer col-auto text-violet-900 focus:ring-blue-400 border-2 border-gray-400 rounded disabled:text-gray-300 disabled:cursor-not-allowed disabled:border-gray-300;

    &:checked {
      @apply border-primary- disabled:border-gray-300;

      &:hover {
        @apply border-primary- disabled:border-gray-300;
      }
    }

    &:hover {
      @apply border-gray-300;
    }
  }

  .checkbox-label {
    @apply text-gray-500;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
  }

  &.very-small {
    .checkbox-input {
      @apply w-3 h-3 mr-2;
    }

    .checkbox-label {
      @apply text-caption-2 text-gray-900 font-semibold;
    }
  }

  &.small {
    .checkbox-input {
      @apply w-3.5 h-3.5;
    }

    .checkbox-label {
      @apply text-caption-1;
    }
  }

  &.medium {
    .checkbox-input {
      @apply w-5 h-5;
    }

    .checkbox-label {
      @apply text-body;
    }
  }
}
