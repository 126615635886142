.info-icon-container {
  @apply flex;
  .tool-tip {
    @apply w-1 h-1 z-10;

    .tool-tip-card {
      @apply absolute bg-white invisible p-3 rounded-md shadow-lg h-9;
    }

    .tool-tip-card::after {
      @apply absolute right-full border-solid;
      content: " ";
      top: 6%;
      margin-top: -0.5rem;
      border-width: 0.5rem;
      border-style: solid;
      border-color: transparent white transparent transparent;
    }
  }
  &:hover {
    .tool-tip {
      .tool-tip-card {
        @apply visible;
      }
    }
  }
}
