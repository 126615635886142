.rc-stepper {
  cursor: default !important;

  &.active {
    @apply border border-solid border-fuchsia-700 scale-125;
  }

  &:disabled {
    @apply border border-solid border-gray-300;
  }
}
