.radio {
  @apply inline-grid grid-cols-2 items-center w-full text-body text-gray-500 font-medium;
  grid-template-columns: auto 1fr;

  .radio-input {
    @apply relative col-auto text-white mr-2 w-5 h-5 focus:ring-blue-400 border-2 rounded-full hover:border-gray-400 disabled:hover:border-gray-500;

    &.disabled {
      @apply border-gray-300 hover:border-gray-300;

      &:checked {
        @apply border-gray-300;

        &:hover {
          @apply border-gray-300;
        }

        &:after {
          @apply transition-all bg-gray-300 w-2.5 h-2.5;
          top: 3px;
          left: 3px;
        }
      }
    }

    &:checked {
      @apply border-primary-;

      &:hover {
        @apply border-primary-;
      }

      &:after {
        @apply transition-all bg-primary- w-2.5 h-2.5;
        top: 3px;
        left: 3px;
      }
    }

    &:after {
      content: " ";
      @apply transition-all absolute bg-white w-0 h-0 rounded-full top-2 left-2;
    }
  }
}
