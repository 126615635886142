.share-of-shelf {
  @apply grid mx-5 mt-10 gap-5;

  @media (min-width: 1600px) {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  @media (min-width: 1800px) {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  @media (min-width: 2000px) {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  @media (min-width: 2200px) {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  @media (min-width: 2400px) {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  grid-template-columns: 1fr 1fr 1fr;
}
