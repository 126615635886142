.tabs-container {
  @apply relative mb-5;

  .tabs {
    @apply grid grid-flow-col items-center justify-start gap-10 border-b border-gray-200;

    .tab-button {
      @apply inline-block text-callout font-semibold text-gray-500 px-3
      after:block after:content-[''] after:w-0 after:h-[3px] after:bg-primary- after:mt-3 after:-ml-3 after:transition-all after:duration-500;

      .tab-icon {
        @apply ml-2 mt-0.5 text-primary-;
      }

      &.selected {
        @apply text-gray-900;
      }

      &.selected::after {
        width: calc(100% + 24px);
      }

      &.disabled {
        @apply text-gray-400 pointer-events-none;
      }
    }
  }

  .tabs-actions {
    @apply justify-self-end mb-2.5;
  }
}
