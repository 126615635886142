.keywords-chip {
  @apply flex bg-blue-100 w-auto py-1 px-2 rounded-md items-center justify-center;

  .cancel-icon {
    @apply text-gray-500;
  }

  .cancel-icon:hover {
    @apply text-red-500 font-medium;
  }

  &.disabled {
    .chip-label,
    .cancel-icon {
      @apply text-white;
    }

    @apply bg-gray-300;
  }
}
