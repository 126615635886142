.main {
  @apply grid;

  .no-plan-warning {
    @apply grid grid-flow-col items-center gap-3 w-full border p-3;
    grid-template-columns: 0fr 2fr;
  }

  .screen-size-container {
    @apply 4xl:max-w-[2000px] max-w-[1360px] mx-auto;
  }
}

