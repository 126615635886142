.loader {
  @apply grid;
}

.add-margin {
  span {
    margin-top: 10px;
  }
}

.loader-primary {
  display: flex;
  justify-content: center;
  flex-direction: column;
  span {
    @apply text-gray-800 text-title-3;
  }
}
