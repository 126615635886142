.switch {
  @apply flex items-center cursor-pointer;

  .switch-control {
    @apply relative;
  }

  .switch-background {
    @apply block bg-gray-300 rounded-full;
    width: 46px;
    height: 18px;
  }

  .dot {
    @apply absolute left-0.5 top-0.5 bg-white w-3.5 h-3.5 rounded-full transition;
  }

  .switch-on-label,
  .switch-off-label {
    @apply absolute text-caption-2 font-semibold uppercase;
    top: 3px;
  }

  .switch-on-label {
    @apply hidden left-1.5 text-white;
  }

  .switch-off-label {
    @apply inline-block right-2 text-gray-500;
  }

  input:checked ~ {
    .switch-background {
      @apply bg-fuchsia-900;
    }

    .dot {
      transform: translateX(200%);
    }

    .switch-on-label {
      @apply block;
    }

    .switch-off-label {
      @apply hidden;
    }
  }

  &.disabled {
    @apply opacity-50 pointer-events-none;
  }

  &.withoutLabel {
    .switch-background {
      width: 36px;
      height: 16px;
    }

    .dot {
      @apply w-3 h-3;
    }

    input:checked ~ {
      .dot {
        transform: translateX(165%);
      }
    }
  }
}
