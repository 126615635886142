.shelf-results {
  @apply grid;

  .header {
    grid-template-columns: 1fr 1fr;
  }

  img {
    object-fit: contain;
  }
}
