.suspense-loader {
  @apply h-full w-full relative;

  .overlay {
    @apply absolute inset-0 flex items-center justify-center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1;
  }

  .loader-position {
    @apply absolute left-1/2 top-1/2 translate-x-2/4 translate-y-2/4 text-primary-;
  }
}
