.aggregated-dashboard {
  @apply pb-5;

  .option-first-row {
    @apply bg-white shadow rounded-t-lg pt-7 pb-2 px-7 text-title-2;
  }

  .option-second-row {
    @apply bg-white shadow px-7 pb-4 pt-2 4xl:text-headline text-body text-gray-500;
  }

  .option-third-row {
    @apply bg-white shadow px-7 pb-7 rounded-b-lg grid grid-cols-2 gap-3;
  }

  .core-content-container {
    @apply min-h-[700px] flex; // TODO figure out how to pass arbitrary values as last class in JIT Compilation
  }

  .summary-content-container {
    min-height: 58px;
    height: 58px;
  }

  .recommendations-row-title {
    @apply text-black text-footnote font-bold;
  }

  .flex-inner {
    flex: 2 2;
  }

  .overall-summary-container {
    b {
      @apply text-black;
    }
  }
}
