.notifications-list {
  @apply grid;

  &.pop-up {
    @apply w-full overflow-y-hidden;

    .list-title {
      @apply px-6 text-gray-400 text-footnote font-semibold mb-1;
    }

    .notification {
      @apply grid mb-1;
      grid-template-columns: 3fr 1.5fr 0.5fr;

      .notification-type {
        @apply mt-2 mb-1 px-6 text-gray-900 text-body font-medium;
      }

      .notification-description {
        @apply mb-2 px-6 text-gray-500 font-medium text-body;
      }

      .notification-details {
        @apply grid justify-end;
        grid-template-rows: 0.5fr 0.5fr;

        .notification-date {
          @apply mb-1 text-gray-500 text-caption-1 font-normal self-end;
        }

        .notification-priority {
          @apply text-right text-footnote font-semibold;

          .high {
            @apply border border-red-900 bg-red-50 text-red-900 p-1 rounded;
          }

          .medium {
            @apply border border-yellow-900 bg-yellow-50 text-yellow-900 p-1 rounded;
          }

          .low {
            @apply border border-blue-900 bg-blue-50 text-blue-900 p-1 rounded;
          }
        }
      }

      .unread-dot {
        @apply w-3 h-3 bg-fuchsia-600 rounded-md self-center justify-self-center;
      }
    }
  }

  &.page {
    @apply w-full grid bg-white shadow rounded-lg p-5 content-start overflow-hidden;

    .list-title {
      @apply text-black text-headline font-semibold mb-5;
    }

    .notification {
      @apply grid mb-3 border border-gray-200 rounded-lg;
      grid-template-columns: 5fr 1.5fr 0.5fr;

      .notification-type {
        @apply mt-3 mb-1 px-6 text-gray-900 text-body font-medium;
      }

      .notification-description {
        @apply mb-3 px-6 text-gray-500 font-medium text-body;
      }

      .notification-details {
        @apply grid justify-end;
        grid-template-rows: 0.5fr 0.5fr;

        .notification-date {
          @apply text-gray-500 text-caption-1 font-normal mt-3;
        }

        .notification-priority {
          @apply text-right text-footnote font-semibold;

          .high {
            @apply border border-red-900 bg-red-50 text-red-900 p-1 rounded;
          }

          .medium {
            @apply border border-yellow-900 bg-yellow-50 text-yellow-900 p-1 rounded;
          }

          .low {
            @apply border border-blue-900 bg-blue-50 text-blue-900 p-1 rounded;
          }
        }
      }

      .unread-dot {
        @apply w-3 h-3 bg-fuchsia-600 rounded-md self-center justify-self-center;
      }
    }
  }
}
