.field-mapping-page-container {
  @apply w-full h-full flex flex-col bg-gray-50;

  .stepper-container {
    @apply 3xl:max-w-[800px] 3xl:min-w-[800px] max-w-[500px] min-w-[500px] mx-auto py-1.5;
  }

  .step-content-container {
    @apply flex flex-grow mx-5 bg-white rounded-lg;
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.05), 0px 1px 6px 0px rgba(0, 0, 0, 0.03);
    height: calc(100% - 92px);

    .step-body {
      @apply flex flex-col flex-grow pt-[35px] px-[25px];
      height: calc(100% - 73px);
    }

    .step-button-bar {
      @apply flex justify-end items-center py-4 border-t pr-5;
      box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
