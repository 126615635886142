.add-edit-audit-container {
  @apply flex flex-col h-full;

  .add-edit-audit-stepper-container {
    @apply 3xl:max-w-[800px] max-w-[500px] mb-1 -mt-8 relative left-1/2 -translate-x-1/2;
  }

  .add-edit-audit-card-container {
    @apply flex flex-col flex-grow relative px-0;
    height: calc(100vh - 230px);

    .add-edit-audit-card-header {
      @apply border-b border-gray-200;
      margin: 0 20px 0 20px;
    }

    .add-edit-audit-card-content {
      @apply flex flex-col flex-grow h-full;
      height: calc(100% - 100px);

      .add-edit-audit-card-body {
        @apply h-full overflow-y-auto;
        margin: 0 20px 0 20px;
        padding: 20px 0 20px 0;
      }

      .add-edit-audit-card-footer {
        @apply flex justify-end w-full p-[20px] pb-0;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 8px 8px;
        z-index: 2;
      }
    }
  }
}
