.notifications-page {
  height: calc(100vh - 140px);
  grid-template-columns: auto 1fr;
  @apply relative grid justify-start;

  .notifications-filters {
    @apply grid bg-white z-0 w-56 pt-9 content-start fixed h-full shadow;

    .notifications-filters-title {
      @apply text-sub-headline font-semibold text-fuchsia-900 mb-4 pl-5;
    }

    .notifications-filters-item {
      @apply hover:bg-gray-100 hover:text-black py-3 pl-5 text-body font-medium text-gray-500 hover:cursor-pointer;
    }

    .selected-item {
      @apply bg-gray-100 text-black;
    }
  }

  .notifications-content {
    @apply h-full px-5 py-7 ml-56;
    width: calc(100vw - 250px);

    .notifications-header {
      @apply grid items-center mb-5;
      grid-template-columns: 10fr auto;
    }
  }
}
