.page-not-found-wrapper {
  @apply w-full h-full p-[2%]  shadow-md;

  .card-container {
    @apply h-full min-w-full flex flex-col overflow-hidden justify-center;

    .icon-positioning {
      @apply flex p-[2%] justify-center;
    }

    .content-wrapper {
      @apply flex flex-col;

      .main-text {
        @apply flex justify-center text-title-1 font-semibold;
      }

      .secondary-text {
        @apply flex justify-center pt-4 pb-[3%] text-sub-headline text-gray-400;
      }
      
      .button-centering {
        @apply flex pb-[2%] justify-center;
      }
    }
  }
}

.network-error-wrapper {
  @apply 3xl:py-36 3xl:px-80 sm:py-12 sm:px-14 py-28 px-28;

  .card-container {
    @apply h-full min-w-full flex flex-col overflow-hidden;

    .icon-positioning {
      @apply flex sm:pt-[5%] sm:pb-[5%] 3xl:pt-[10%] 3xl:pb-[10%] pt-[7%] pb-[7%] justify-center;
    }

    .content-wrapper {
      @apply flex flex-col;

      .main-text {
        @apply flex justify-center text-title-1 font-semibold;
      }

      .secondary-text {
        @apply flex justify-center pt-[2%] pb-[5%] text-sub-headline text-gray-400 ;
      }
      
      .button-centering {
        @apply flex sm:pb-[5%] 3xl:pb-[10%] justify-center;
      }
    }
  }
}
