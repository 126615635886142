.dashboard {
  height: calc(100vh - 140px);
  grid-template-columns: auto 1fr;
  @apply relative grid justify-start;

  .dashboard-menu {
    @apply grid bg-white z-0 w-56 pt-9 pl-5 content-start fixed h-full shadow;

    .dashboard-menu-title {
      @apply text-headline font-semibold text-gray-900 mb-6;
    }
  }

  .dashboard-menu-item {
    @apply grid gap-2 grid-flow-col justify-start items-center mb-5 text-body hover:cursor-pointer text-gray-500 p-0 h-auto;
  }

  .dashboard-content {
    @apply h-full p-5 ml-56;
    width: calc(100vw - 250px);
  }
}
