.base-form-component {
  @apply relative rounded-lg w-full;

  .base-input {
    margin-bottom: 10px;
  }

  &.error {
    .base-input {
      margin-bottom: 0px;
    }
  }

  .component-label {
    @apply block mb-2 text-caption-1 pl-1 text-gray-900 font-medium;
  }

  .error-message {
    @apply min-h-[6px] block mt-1 mb-2 text-red-500 text-caption-1 text-left;
  }

  .select__control--is-disabled {
    .select__single-value--is-disabled {
      @apply text-gray-300;
    }

    .select__indicators {
      @apply text-gray-300;
    }
    @apply bg-gray-50;
  }
}

.base-input {
  @apply text-body appearance-none border bg-white border-gray-300 placeholder-gray-400
  rounded-md w-full text-gray-800 duration-200 ease-in-out transition-colors h-12;
  padding: 9px 10px 10px 10px;

  &[disabled] {
    .base-input {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &:hover {
    @apply border-gray-400;
  }

  &:focus,
  &:active {
    @apply outline-none border-secondary- border-2 ring-0;
    padding: 8px 9px 9px 9px;
  }

  &.error {
    @apply text-red-900 bg-red-50 border-red-400;
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    @apply bg-gray-50 text-gray-300;
  }
}
