

.tags-info-icon-container {
  .title {
    @apply text-caption-1 text-black font-medium;
  }
  .text {
    @apply text-caption-1 text-gray-500 font-normal;
  }

  .example {
    @apply grid;
    grid-template-columns: 20px auto;
  }
}

