.tags-checkbox {
  @apply h-5 p-0 m-0 flex;

  .checkbox-btn:hover {
    @apply border-gray-300;
  }

  .checkbox-btn {
    @apply w-5 h-5 border-2 rounded border-gray-400 focus:ring-blue-400 flex justify-center items-center  p-0 m-0;
    &:disabled {
      @apply text-gray-300;
      opacity: 0.4;
      &:hover {
        @apply text-gray-300;
        opacity: 1;
      }
    }

    .intermediate {
      @apply w-3 h-3 bg-fuchsia-900 rounded-sm;
    }

    .checked {
      @apply w-5 h-5 bg-fuchsia-900 rounded flex justify-center items-center;
    }
  }
}
