.shelf-query-form {
  @apply grid justify-items-center mb-2;

  .logo {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
  }  

  .formGrid {
    grid-template-columns: 1fr 1fr 2fr auto;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    width: 80%;
  }
}
