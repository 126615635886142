.downloads {
  @apply grid m-page;

  .grid-header {
    @apply mb-3;
  }

  .file-name {
    @apply grid;
    grid-template-columns: 0fr 1fr;
  }
}
